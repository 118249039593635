<template>
  <v-row>
    <v-tabs v-model="tab">
      <v-tab class="mr-3"> Feedback </v-tab>
      <v-tab class="px-1"> Subscribers </v-tab>
      <v-tab-item>
        <release-feedback from-app></release-feedback>
      </v-tab-item>
      <v-tab-item>
        <subscribers-list></subscribers-list>
      </v-tab-item>
    </v-tabs>
  </v-row>
</template>

<script>
import ReleaseFeedback from "@/view/components/App/ReleaseFeedback";
import SubscribersList from "@/view/components/App/SubscribersList";
export default {
  components: { SubscribersList, ReleaseFeedback },
  TABS: {
    "app-feedback": 0,
    "app-subscribers": 1,
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    tab(tab) {
      if (
        this.$route.name.includes(Object.keys(this.$options.TABS)[tab || 0])
      ) {
        return;
      }
      this.$router
        .push({
          name: Object.keys(this.$options.TABS)[tab || 0],
          params: { app_id: this.$route.params.app_id },
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message ||
              "Something went wrong, please reload your page and try again"
          );
        });
    },
  },
  created() {
    const activeTab = this.$route.name;
    this.tab = this.$options.TABS[activeTab] || 0;
  },
};
</script>

<style scoped></style>
