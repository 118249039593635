<template>
  <div>
    <v-row>
      <v-col v-if="errorMessages || getIsNetworkError" cols="12">
        <no-data
          v-if="errorMessages && !getIsNetworkError"
          :first-text="errorMessages"
          with-button
          permission-issues
        ></no-data>
        <vue-no-data v-if="getIsNetworkError"> </vue-no-data>
      </v-col>
      <template v-else>
        <v-col cols="12">
          <v-breadcrumbs :items="breadCrumbs" class="text-body-1 pa-2">
            <template slot="item" slot-scope="props">
              <span
                :class="
                  props.item.disabled
                    ? 'dark-grey-text'
                    : 'indigo--text text--accent-2 pointer'
                "
                @click="$router.push(props.item.to)"
                v-text="props.item.text"
              ></span>
            </template>
            <v-icon slot="divider">mdi-chevron-right</v-icon>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="12" md="3">
          <div>
            <AppInfoCard
              :loader="loading"
              :info="appInfo"
              :id="appID"
              from-dashboard
              class="mb-4"
              :error-message="errorMessages"
            />
            <v-card class="pa-2 mb-15" outlined>
              <v-list vertical>
                <v-list-item
                  :class="index == tab ? 'lightBackground' : ''"
                  cols="12"
                  v-for="(item, index) in menuList"
                  @click="tab = item.value"
                  :key="index"
                >
                  <div v-if="item.tag">
                    {{ item.title }}
                    <v-chip class="mx-5" color="success" x-small label>{{
                      item.tag
                    }}</v-chip>
                  </div>
                  <div v-else>
                    {{ item.title }}
                  </div>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="9">
          <v-row v-if="loading">
            <v-col v-for="i in 3" :key="i" cols="12">
              <v-skeleton-loader
                type="table-heading, list-item-two-line, table-tfoot"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <component
            v-else
            :is="menuList[tab].component"
            :info="appInfo"
            :loader="loading"
            from-app
            :app_id="appID"
            :infoError="errorMessages"
          ></component>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { CLEAR_APP, INFO } from "@/store/apps/app.module.js";
import { mapGetters } from "vuex";
import AppInfoCard from "@/view/components/App/AppInfoCard.vue";
import { get, isEmpty } from "@/core/services/helper.service.js";
import VueNoData from "@/view/components/Common/VueNoData";
import ReleaseFeedback from "@/view/pages/app/feedback-subscribers";

export default {
  components: {
    VueNoData,
    AppInfoCard,
    AppDashboard: () => import("@/view/pages/app/dashboard"),
    AppReleases: () => import("@/view/pages/app/releases"),
    AppMembers: () => import("@/view/components/App/MembersAndInvites.vue"),
    AppInstalls: () => import("@/view/pages/app/install-export.vue"),
    AppTimeline: () => import("@/view/components/App/Timeline.vue"),
    ShareLink: () => import("@/view/components/App/ShareLink.vue"),
    AppFeedback: () => import("@/view/components/App/ReleaseFeedback.vue"),
    AppShareSettings: () => import("@/view/pages/app/share-setting.vue"),
    MessageBox: () => import("@/view/components/App/Chat/MessageBox.vue"),
    ReleaseFeedback,
  },
  TABS: {
    "app-dashboard": 0,
    "app-chat": 1,
    "app-releases": 2,
    "app-share-link": 3,
    "app-installs": 4,
    "app-feedback": 5,
    "app-members": 6,
    "app-settings": 7,
    "app-subscribers": 5,
    "app-share-settings": 3,
  },
  data() {
    return {
      isEmpty,
      get,
      fromShare: false,
      breadCrumbsItem: {
        "app-dashboard": "Dashboard",
        "app-chat": "Chat",
        "app-releases": "Releases",
        "app-share-link": "Share",
        "app-installs": "Installs",
        "app-feedback": "Feedback",
        "app-subscribers": "Subscribers",
        "app-members": "Members",
        "app-share-settings": "Share settings",
      },
      breadCrumbs: [
        { text: "Apps", disabled: false, to: { name: "apps" } },
        {
          text: "...",
          disabled: false,
          to: { name: "app", params: { app_id: "" } },
        },
        { text: "...", disabled: true },
      ],
      menuList: [
        {
          title: "Dashboard",
          component: "app-dashboard",
          value: 0,
        },
        {
          title: "Chat",
          component: "message-box",
          tag: "",
          value: 1,
        },
        {
          title: "Releases",
          component: "app-releases",
          value: 2,
        },
        {
          title: "Share",
          component: "app-share-settings",
          tag: "",
          value: 3,
        },
        {
          title: "Installs",
          component: "app-installs",
          value: 4,
        },
        {
          title: "Feedback",
          component: "release-feedback",
          tag: "",
          value: 5,
        },
        {
          title: "Members",
          component: "app-members",
          value: 6,
        },
      ],
      tab: 0,
      loading: false,
      errorMessages: null,
      readMoreDesc: false,
      appID: "",
    };
  },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
      getIsNetworkError: "getIsNetworkError",
    }),
  },
  watch: {
    "$route.path"() {
      const activeTab = this.$route.name;
      this.tab = this.$options.TABS[activeTab] || 0;
    },
    "$route.name"(val) {
      if (Object.keys(this.breadCrumbsItem).includes(val)) {
        this.breadCrumbs[2].text = this.breadCrumbsItem[val];
      }
    },
    tab(tab) {
      if (
        this.$route.name.includes(Object.keys(this.$options.TABS)[tab || 0])
      ) {
        return;
      }
      this.$router
        .push({
          name: Object.keys(this.$options.TABS)[tab || 0],
          params: { app_id: this.$route.params.app_id },
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message || "Something went wrong.");
        });
    },
  },
  created() {
    const activeTab = this.$route.name;
    this.tab = this.$options.TABS[activeTab] || 0;
    this.appID = this.$route.params.app_id;
    this.fetchInitialData();
    if (!this.canManageApp) {
      this.menuList[6].disable = true;
    }
    if (this.appInfo && this.appInfo.name) {
      this.breadCrumbs[1].text = this.appInfo.name;
      this.breadCrumbs[1].to = {
        name: "app-dashboard",
        params: { app_id: this.appID },
      };
    }
    if (Object.keys(this.breadCrumbsItem).includes(this.$route.name)) {
      this.breadCrumbs[2].text = this.breadCrumbsItem[this.$route.name];
    }
  },
  methods: {
    fetchInitialData() {
      this.errorMessages = null;
      this.appID = this.$route.params.app_id;
      this.$store.dispatch(CLEAR_APP);
      if (isEmpty(this.appInfo)) {
        this.loading = true;
        this.$store
          .dispatch(INFO, this.appID)
          .then(() => {
            this.breadCrumbs[1].text = this.appInfo.name;
            this.breadCrumbs[1].to = {
              name: "app-dashboard",
              params: { app_id: this.appID },
            };
            this.errorMessages = null;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.errorMessages = err.message || null;
          });
      }
    },
  },
};
</script>

<style scoped></style>
