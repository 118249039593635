<template>
  <div>
    <v-card class="transparent" tile flat :disabled="busy">
      <div class="row">
        <div class="col-2"></div>
        <h1 class="col-8 m-0 bold-text text-center display-1">Edit</h1>
      </div>
      <div class="row">
        <v-form
          ref="ios"
          class="form col-12 mt-7"
          @submit.prevent="() => edit()"
        >
          <div class="d-flex justify-center">
            <div class="text-center mb-2 position-relative">
              <div v-if="loading">
                <ContentLoader></ContentLoader>
              </div>
              <div v-else>
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <v-badge :value="!!app.image" light color="grey" avatar>
                      <template v-slot:badge>
                        <v-icon
                          style="cursor: pointer"
                          @click="onDeleteImage"
                          size="20"
                          color="white"
                          >close</v-icon
                        >
                      </template>
                      <!-- <v-avatar style="border: none" size="100" class="mb-7"> -->
                      <img
                        :src="info.image || getAppImage(app)"
                        width="100"
                        height="100"
                        @error="replaceDefaultAppImage"
                      />
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover && !loading"
                          absolute
                          color="black"
                        >
                          <label>
                            <v-icon
                              @click="appImageUpload = true"
                              style="cursor: pointer"
                              v-if="!loading"
                              >edit</v-icon
                            >
                            <v-btn v-if="loading" icon :loading="loading">
                            </v-btn>
                          </label>
                        </v-overlay>
                      </v-fade-transition>
                      <!-- </v-avatar> -->
                    </v-badge>
                  </template>
                </v-hover>
              </div>
            </div>
          </div>
          <div class="text-muted text-center mb-4">
            100x100 pixels for the public install page
          </div>
          <v-text-field
            id="editAppName"
            label="Name"
            v-model="form.name"
            counter="30"
            placeholder="App name"
            v-validate="'required|min:3|max:30'"
            v-bind="veeValidate('Name', 'Name')"
            filled
          />
          <v-btn
            id="editAppSubmit"
            :loading="busy"
            :disabled="errors.any() || busy || !form.name"
            class="large main wide primary mx-auto mt-13"
            @click="edit"
            >Update</v-btn
          >
        </v-form>
      </div>
    </v-card>
    <div>
      <image-upload
        v-if="appImageUpload"
        v-model="appImage"
        hide-overlay
        :view-port="{width: 100, height: 100}"
        @close="appImageUpload = false"
        @cropped-image="onFileUpload"
      ></image-upload>
    </div>
  </div>
</template>

<style scoped>
.app-logo img {
  height: 100px;
  width: 100px;
}

.app-logo input {
  height: 0px;
  width: 0px;
}

.logo-edit {
  right: -8px;
  top: -8px;
}
</style>

<script>
import Vue from "vue";
import { get } from "@/core/services/helper.service";
import veeValidate from "@/mixins/veeValidate";
import { UPDATE, UPDATE_IMAGE, DELETE_IMAGE } from "@/store/apps/app.module";
import { mapState } from "vuex";
import ImageUpload from "@/view/components/Common/ImageUpload";

export default {
  name: "edit-app",
  props: ["app", "onClose"],
  mixins: [veeValidate],
  submitLoader: false,
  components: { ImageUpload },
  data() {
    return {
      get,
      busy: false,
      loading: false,
      appImage: "",
      appImageUpload: false,
      form: {
        name: "",
        description: "",
        public: false,
      },
    };
  },
  watch: {
    app: function (value) {
      this.form.name = get(value, "name", "");
    },
  },
  computed: {
    ...mapState({
      info: (state) => state.app.info,
    }),
  },
  mounted() {
    this.form.name = this.app.name;
    this.form.description = this.app.description;
    this.form.public = this.app.public;
    Vue.prototype.$analytics.logEvent("edit_app_start");
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        name: "",
        description: "",
        public: false,
      };
      // this.$nextTick(() => {
      //   this.$reset();
      // });
    },
    async edit() {
      if (this.errors.any() || this.busy) {
        this.busy = false;
        return false;
      }

      this.busy = true;

      const name = this.form.name;

      try {
        const response = await this.$store.dispatch(UPDATE, {
          id: this.app.id,
          name,
        });
        this.busy = false;
        this.onClose && this.onClose();
        this.$emit("success", { name });
        this.notifyUserMessage({
          message: response.message || "App details updated successfully",
          timeout: true,
        });
        this.$emit("close");
        Vue.prototype.$analytics.logEvent("edit_app");
      } catch (err) {
        this.notifyErrorMessage(err.message || "Update failed");
        Vue.prototype.$analytics.logEvent("edit_app_error");
      }
      this.busy = false;
    },
    async onFileUpload(e) {
      Vue.prototype.$analytics.logEvent("app_image_start");

      if (this.loading) return;
      this.loading = true;
      const form = new FormData();
      form.append("image", e);
      try {
        const response = await this.$store.dispatch(UPDATE_IMAGE, {
          id: this.app.id,
          image: form,
        });
        this.$emit("close");
        this.notifyUserMessage(
          response.message || "App image successfully updated"
        );
        this.loading = false;
        Vue.prototype.$analytics.logEvent("app_image");
      } catch (err) {
        this.notifyErrorMessage(
          err.message || "Failed to update the app image"
        );
        this.loading = false;
        Vue.prototype.$analytics.logEvent("app_image_error");
      }
      this.loading = false;
    },
    onDeleteImage() {
      Vue.prototype.$analytics.logEvent("delete_app_image_start");
      this.$store
        .dispatch(DELETE_IMAGE, this.app.id)
        .then((response) => {
          this.notifyUserMessage(
            response.message || "App image successfully removed"
          );
          Vue.prototype.$analytics.logEvent("delete_app_image");
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || `Something went wrong, please try again`
          );
          Vue.prototype.$analytics.logEvent("delete_app_image_error");
        });
    },
  },
};
</script>
