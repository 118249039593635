var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4"},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.expressAndBoth ? 2 : 1),function(i){return _c('v-col',{key:i,class:{
        'divider-first':
          i == 1 && (_vm.expressAndBoth || _vm.$vuetify.breakpoint.mdOnly),
      },attrs:{"cols":"12","md":_vm.expressAndBoth ? 6 : 12}},[_c('h2',{staticClass:"display-1 mb-4 mt-2 text-center bold-text text-capitalize mx-4"},[_vm._v(" Install "),(_vm.appName(i))?_c('span',{class:{ 'd-block': _vm.expressAndBoth }},[_vm._v(" “"+_vm._s(_vm.appName(i))+"” ")]):_vm._e()]),(_vm.expressAndBoth || !_vm.both)?_c('div',{staticClass:"mx-4 font-size-h3 text-center darkGrey--text"},[(_vm.expressAndBoth)?[_vm._v(" "+_vm._s(i == 1 ? ("v" + (_vm.appInfo.apk.info.version) + " (" + (_vm.appInfo.apk.info.version_code) + ")") : ("v" + (_vm.appInfo.ipa.info.version) + " (" + (_vm.appInfo.ipa.info.version_code) + ")"))+" ")]:[_vm._v(" "+_vm._s(("v" + (_vm.appInfo.version) + " (" + (_vm.appInfo.version_code) + ")"))+" ")]],2):_vm._e(),_c('div',{staticClass:"pt-2 mx-4 font-size-h3 text-center darkGrey--text"},[(_vm.expressAndBoth)?_c('span',[_vm._v(" "+_vm._s(i == 1 ? "For android" : "For ios")+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.platform ? ("For " + _vm.platform) : "")+" ")])]),(_vm.getMobileOperatingSystem !== 'unknown')?_c('div',[(!['android', 'ios'].includes(_vm.platform))?_c('install-button',{attrs:{"release-id":_vm.appInfo.releaseId || null,"is-public":_vm.appInfo.public,"release-url":_vm.appInfo.url,"app-id":_vm.appInfo.id}}):_vm._e(),(
            (_vm.platform === 'android' &&
              _vm.getMobileOperatingSystem === 'android') ||
            (_vm.expressAndBoth && i == 1)
          )?_c('install-button',{attrs:{"is-public":_vm.appInfo.public,"platform":"android","release-url":_vm.appInfo.url,"release-id":_vm.appInfo.releaseId || null,"app-id":_vm.appInfo.id}}):_vm._e(),(
            (_vm.platform === 'ios' && _vm.getMobileOperatingSystem === 'ios') ||
            (_vm.expressAndBoth && i == 2)
          )?_c('install-button',{attrs:{"platform":"ios","is-public":_vm.appInfo.public,"release-url":_vm.appInfo.url,"release-id":_vm.appInfo.releaseId || null,"app-id":_vm.appInfo.id}}):_vm._e()],1):_vm._e(),_c('div',[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('qrcode',{attrs:{"value":_vm.link(i),"options":{ width: 200 }}})],1),(_vm.both || !_vm.platform)?_c('div',{staticClass:"font-size-h4 text-center mx-7 mb-2 mt-2"},[_vm._v(" Scan this QR code from your device ")]):(_vm.platform)?_c('div',{staticClass:"text-center mb-6 font-size-h4 darkGrey--text mx-auto",staticStyle:{"max-width":"360px"}},[_vm._v(" "+_vm._s(("Scan this QR code from your " + _vm.platform + " device or open our app to install it"))+" ")]):_vm._e(),(_vm.both && !_vm.isExpress)?_c('div',{staticClass:"mx-auto bodyFont mt-4 mb-5",staticStyle:{"max-width":"400px"}},[_vm._v(" Note: You can invite "),_c('InviteMembersPanel',{staticClass:"d-inline primary--text",attrs:{"hide-overlay":"","display-text":"new team members"}}),_vm._v(" at anytime from Team page to access this app and others. ")],1):_vm._e(),_c('div',{staticClass:"mt-4 text-center"},[_c('v-chip',{staticClass:"radius-10 darkGrey--text"},[_c('MaxText',{attrs:{"text":_vm.link(i),"max":34}})],1),_c('v-icon',{staticClass:"ml-2",domProps:{"textContent":_vm._s('mdi-content-copy')},on:{"click":function($event){_vm.copy(_vm.link(i))}}})],1)])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }