<template>
  <div>
    <div v-if="loader || stillWaitingForData">
      <v-skeleton-loader type="list-item-avatar, article" class="mb-4" />
      <v-skeleton-loader type="article" />
    </div>
    <!-- <contentLoader height="150" /> -->
    <div
      class="d-flex flex-center dark-grey-text text-center min-h-200px"
      v-else-if="noData"
    >
      <no-data :first-text="errorMessage" permission-issues></no-data>
    </div>
    <div v-else>
      <v-card class="radius-10 fill-width" outlined>
        <action-dropdown id="appInfoDropdown" absolute>
          <template #list>
            <v-list-item id="edit-app" v-if="canManageApp" @click="onEditClick">
              <v-icon small>edit</v-icon>edit
            </v-list-item>
            <!--            <v-list-item @click="openInstall"-->
            <!--              ><v-icon small>mdi-cellphone-iphone</v-icon>install-->
            <!--            </v-list-item>-->
            <v-list-item id="leave-app" @click="onLeaveClick">
              <v-icon small>exit_to_app</v-icon>leave
            </v-list-item>
            <v-list-item
              v-if="canManageApp"
              id="delete-app"
              @click="onDeleteClick"
            >
              <v-icon small>mdi-close-circle</v-icon>Delete
            </v-list-item>
          </template>
        </action-dropdown>

        <div class="pa-4">
          <img :alt="info.name" class="app-img-size" :src="getAppImage(info) "  @error="replaceDefaultAppImage"/>
          <v-card-title id="appInfoName" class="px-0 pt-2">
            {{ info.name }}
          </v-card-title>
          <!--          <div style="white-space: pre-line" class="text-muted mb-3">-->
          <!--            <div v-if="readMoreDesc">-->
          <!--              {{ info.description }}-->
          <!--              <span-->
          <!--                class="primary&#45;&#45;text cursor-pointer"-->
          <!--                @click="readMoreDesc = !readMoreDesc"-->
          <!--                >{{ "Read less" }}</span-->
          <!--              >-->
          <!--            </div>-->
          <!--            <div v-if="!readMoreDesc">-->
          <!--              {{-->
          <!--                info && info.description-->
          <!--                  ? info.description.substring(0, 115)-->
          <!--                  : ""-->
          <!--              }}-->
          <!--              <span-->
          <!--                v-if="info && info.description && info.description.length > 115"-->
          <!--                class="primary&#45;&#45;text cursor-pointer"-->
          <!--                @click="readMoreDesc = !readMoreDesc"-->
          <!--                >{{ "Read more..." }}</span-->
          <!--              >-->
          <!--            </div>-->
          <!--          </div>-->
          <v-row no-gutters fill-height>
            <v-col align-self="end">
              <div class="d-flex mb-2">
                <span
                  label
                  small
                  class="dark-grey-text bold-text text-body-1 px-0"
                  >App ID</span
                >
                <v-spacer />
                <span class="mr-1">
                  {{ info.id }}
                </span>
                <VueTooltip
                  icon-color="grey"
                  color="primary"
                  text-class="white--text"
                  :v-html="'App ID can be used in our CLI or other integrations for automated deployments'"
                  :max-width="200"
                />
              </div>
              <div class="d-flex mb-2">
                <span
                  label
                  small
                  class="dark-grey-text bold-text text-body-1 mr-3 px-0"
                >
                  Platform
                </span>
                <v-spacer />
                <PlatformIcon
                  size="20"
                  v-for="i in 2"
                  :key="i"
                  :android="i == 1"
                  :apple="i == 2"
                  :color="
                    get(info, 'platforms', []).indexOf(
                      i == 1 ? 'android' : 'ios'
                    ) == -1
                      ? 'grey'
                      : 'primary'
                  "
                  class="pointer mr-1"
                />
              </div>
              <div class="d-flex my-3 mb-2" v-if="canManageApp">
                <span
                  label
                  small
                  class="dark-grey-text bold-text text-body-1 mr-3 px-0"
                >
                  Sharing</span
                >
                <v-spacer />
                <v-tooltip bottom transition="slide-y-transition">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      @click="
                        $router.push({
                          name: 'app-share-link',
                          params: { app_id: $route.params.app_id },
                        })
                      "
                      v-on="on"
                      icon
                    >
                      <v-icon
                        v-bind="attrs"
                        small
                        :color="info.public ? 'primary' : 'success'"
                        v-text="
                          info.public ? 'mdi-lock-open-outline' : 'mdi-lock'
                        "
                      ></v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{
                      info.public
                        ? "Can be accessed publicly"
                        : "Accessed by your team members only"
                    }}
                  </span>
                </v-tooltip>
              </div>
              <div class="d-flex mb-2">
                <span
                  label
                  small
                  class="dark-grey-text bold-text text-body-1 mr-3 px-0"
                  >Created</span
                >
                <v-spacer />
                <vue-hoverable-date :date="info.created_at" />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-card
        outlined
        class="mt-4 radius-10"
        v-if="canManageApp && !fromDashboard"
      >
        <v-card-title class="pt-3 pb-0"> Stats </v-card-title>

        <div class="pt-4 pb-4 pl-4 pr-6 d-flex">
          <span
            label
            small
            class="dark-grey-text bold-text text-body-1 mr-3 px-0"
          >
            Releases</span
          >
          <v-spacer />
          {{ get(info, "stats.total_releases", "0") || "0" }}
        </div>
        <div class="pb-4 pl-4 pr-6 d-flex">
          <span
            label
            small
            class="dark-grey-text bold-text text-body-1 mr-3 px-0"
          >
            Members</span
          >
          <v-spacer />
          {{ get(info, "stats.total_members", "0") }}
        </div>
        <div class="pb-4 pl-4 pr-6 d-flex">
          <span
            label
            small
            class="dark-grey-text bold-text text-body-1 mr-3 px-0"
          >
            Total Installs</span
          >
          <VueTooltip
            icon-color="blue-grey lighten-2"
            color="primary"
            text-class="white--text"
            :v-html="`Total installs and downloads <br /> <div class='my-3'>We are updating this info every minute.</div>`"
          />
          <v-spacer />
          <span class=""> {{ totalInstalls }}</span>
        </div>
      </v-card>

      <v-card
        outlined
        class="mt-4 radius-10"
        v-if="canManageTeam && !fromDashboard"
      >
        <v-card-title class="pt-3 pb-0"> Storage </v-card-title>
        <div class="pa-4 d-flex">
          <img
            class="mb-n1"
            height="24"
            v-if="get(info, 'storage.icon', null)"
            :src="
              get(info, 'storage.icon', null) == 'testappio'
                ? getStorageImg('testapp.io.svg')
                : getStorageImg(get(info, 'storage.icon', null))
            "
          />
          <span class="ml-3 dark-grey-text bold-text text-body-1">
            <MaxText :text="get(info, 'storage.name', '-')" max="30" />
          </span>
          <v-spacer />
          {{
            info && info.storage && info.storage.usage > 0
              ? formatBytes(get(info, "storage.usage", 0))
              : formatBytes(0)
          }}
          <!-- {{ formatBytes(get(info, "storage.usage", 0)) }} -->
        </div>
      </v-card>
    </div>

    <Modal v-model="deleteModal" no-py>
      <template #message>
        <v-card-title class="font-weight-bold">Delete app</v-card-title>
        <v-divider />
        <v-card-text class="message">
          <p>Are you sure you want to delete this app?</p>

          <v-alert class="mt-3" border="left" color="info" icon="info" outlined>
            You will lose the access to any information regarding this app.
            <div v-if="readMore">
              <br />
              This app will be deleted along with everything attached to it such
              as:<br />
              <br />

              <ul>
                <li>Releases</li>
                <li>Timeline</li>
                <li>Notifications</li>
              </ul>

              <br />
            </div>
            <b class="pointer" @click="readMore = !readMore" v-if="!readMore">
              {{ "Read more..." }}
            </b>
            <br />
          </v-alert>
          <p>There's no undo for this action.</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn id="leaveAppCancelBtn" class="main" @click="onCancel"
            >no</v-btn
          >
          <v-btn
            class="main primary"
            id="leaveAppConfirmBtn"
            @click="deleteAppConfirm"
            :loading="loading"
            >yes</v-btn
          >
        </v-card-actions>
      </template>
    </Modal>
    <Modal v-model="modal" no-py>
      <template #message>
        <v-card-title class="font-weight-bold">Leave app</v-card-title>
        <v-divider />
        <v-card-text class="message">
          <p>Are you sure you want to leave this app?</p>

          <v-alert class="mt-3" border="left" color="info" icon="info" outlined>
            You will lose the access to any information regarding this app.
            <div v-if="readMore">
              <br />
              If you are the only one in the app, the app will be deleted along
              with everything attached to it such as:<br />
              <br />

              <ul>
                <li>Releases</li>
                <li>Timeline</li>
                <li>Notifications</li>
              </ul>

              <br />
              Otherwise, your data such as releases will remain the same in the
              app but you will not have the access to them. However, you can
              still be added {{ canManageTeam ? "or rejoin" : "to" }} the app
              again at any time if it's still exits.
            </div>
            <b class="pointer" @click="readMore = !readMore" v-if="!readMore">
              {{ "Read more..." }}
            </b>
            <br />
          </v-alert>
          <p>There's no undo for this action.</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn id="leaveAppCancelBtn" class="main" @click="onCancel"
            >no</v-btn
          >
          <v-btn
            class="main primary"
            id="leaveAppConfirmBtn"
            @click="onConfirm"
            :loading="loadingConfirm"
            >yes</v-btn
          >
        </v-card-actions>
      </template>
    </Modal>
    <Modal
      style="z-index: 99999"
      v-model="installModal"
      :width="660"
      :loading="loading"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <template #message>
        <InstallModal
          v-if="installModal"
          platform=""
          :both="forBoth"
          :app-info="{
            releaseId: '',
            url: '',
            ...info,
          }"
          :from-app-info="fromAppInfo"
        />
      </template>
    </Modal>
    <Modal hideOverlay v-model="viewEditApp" @close="viewEditApp = false">
      <template #message>
        <EditAppPanel
          @success="$emit('success', $event)"
          :app="info"
          @close="viewEditApp = false"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { isEmpty, get } from "@/core/services/helper.service";
import { mapMutations, mapState } from "vuex";
import { getReleaseTime, formatBytes } from "@/core/services/helper.service";
import ActionDropdown from "@/view/components/Common/ActionDropdown";
import InstallModal from "@/view/components/App/InstallModal.vue";
import EditAppPanel from "@/view/components/App/EditAppPanel.vue";
import Vue from "vue";
import { LEAVE_APP, DELETE_APP } from "@/store/apps/app.module.js";

export default {
  name: "app-info-card",
  props: {
    id: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    loader: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    fromDashboard: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InstallModal,
    ActionDropdown,
    EditAppPanel,
  },
  data() {
    return {
      get,
      getReleaseTime,
      formatBytes,
      stillWaitingForData: true,
      loadingConfirm: false,
      forBoth: false,
      deleteModal: false,
      loading: false,
      readMoreDesc: false,
      fromAppInfo: false,
      readMore: false,
      modal: false,
      viewEditApp: false,
      installModal: false,
    };
  },
  computed: {
    ...mapState({
      members: (state) => state.app.members,
    }),
    totalInstalls() {
      const metaKeys = Object.keys(this.info.stats);
      if (
        !metaKeys.includes("total_installs") &&
        !metaKeys.includes("total_downloads")
      ) {
        return "N/A";
      } else {
        return (
          this.info.stats.total_installs ||
          0 + this.info.stats.total_downloads ||
          0
        );
      }
    },
    noData() {
      return isEmpty(this.info) ? true : false;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.stillWaitingForData) {
        this.stillWaitingForData = false;
      }
    }, 1000);
  },
  methods: {
    ...mapMutations({
      removeAppStats: "removeAppStats",
      removeAppFromList: "removeAppFromList",
    }),
    onEditClick() {
      this.viewEditApp = true;
      Vue.prototype.$analytics.logEvent("app", {
        status: "open",
        action: "edit",
      });
    },
    openInstall() {
      this.forBoth = true;
      this.fromAppInfo = true;
      this.installModal = !this.installModal;
    },
    onLeaveClick() {
      this.modal = true;
      Vue.prototype.$analytics.logEvent("app", {
        status: "open",
        action: "leave",
      });
    },
    getAppImage(image, id) {
      if (image || id) {
        if (id) {
          return image || "/media/defaults/icon-120.png";
          // return `${this.assets ? this.assets.url : ''}${this.assets ? this.assets.app : ''}${id}/image/${image}`;
        } else {
          return image.image || "/media/defaults/icon-120.png";
          // return `${this.assets.url}${this.assets ? this.assets.app : ''}${image.id}/image/${image.image}`;
        }
      }
    },
    onDeleteClick() {
      this.deleteModal = true;
      Vue.prototype.$analytics.logEvent("app", {
        status: "open",
        action: "delete",
      });
    },

    deleteAppConfirm() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DELETE_APP, this.info.id)
        .then((response) => {
          this.notifyUserMessage(response.message);
          this.removeAppFromList(this.info.id);
          this.removeAppStats({
            appCount: 1,
          });
          this.$router.push({ name: "apps" }).catch(() => {});
          Vue.prototype.$analytics.logEvent("app", {
            status: "success",
            action: "delete",
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
          Vue.prototype.$analytics.logEvent("app", {
            status: "error",
            action: "leave",
            message: err.message,
          });
        });
    },

    async onConfirm() {
      this.loadingConfirm = true;
      try {
        const response = await this.$store.dispatch(LEAVE_APP, this.info.id);
        this.notifyUserMessage(response.message || "App left successfully");
        this.$router.push({ name: "apps" }).catch(() => {});
        this.loadingConfirm = false;
        this.removeAppFromList(this.info.id);
        this.removeAppStats({
          appCount: 1,
        });
        this.leave = false;
        Vue.prototype.$analytics.logEvent("app", {
          status: "success",
          action: "leave",
        });
      } catch (err) {
        this.modal = false;
        this.notifyErrorMessage(err.message || "Failed to leave app");
        this.loadingConfirm = false;
        Vue.prototype.$analytics.logEvent("app", {
          status: "error",
          action: "leave",
          message: err.message,
        });
      }
    },
    onCancel() {
      this.modal = false;
      this.deleteModal = false;
      Vue.prototype.$analytics.logEvent("app", {
        status: "cancel",
        action: "leave",
      });
    },
  },
};
</script>
