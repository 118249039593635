<template>
  <v-card outlined class="">
    <v-card-title>
      <div>
        <div v-if="!fromShare" class="font-size-h3 dark-grey-text bold-text">
          App subscribers
          <v-chip x-small> Soon </v-chip>
        </div>
        <div class="text-muted bold-text text-body-1">
          <v-chip x-small v-if="fromShare"> Soon </v-chip>
          List of users who subscribed to receive updates about new releases
          from the public install page

          <div class="mt-2" v-if="canManageApp">
            Connect your
            <a @click="$router.push({ name: 'integrations' })">Slack</a> to get
            notified about new feedback and other important events.
          </div>
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <v-skeleton-loader
        class="transparent"
        type="table-thead, table-tbody"
        v-if="loading"
      />
      <v-data-table
        v-else-if="canManageApp && !loading"
        :headers="headers"
        :items="[...getSubscribersList]"
        :page.sync="page"
        :expanded="expanded"
        class="hide-v-data-footer__select release-table"
        :items-per-page="10"
        :server-items-length="getTotalSubscribersCount"
      >
        <template #item.name="{ item }">
          <div class="ml-4">
            {{ get(item, "user.name", "") }}
            <div class="text-muted">
              {{ get(item, "user.email", "") }}
            </div>
          </div>
        </template>
        <template #item.device="{ item }">
          <user-agent-parser v-if="item.device" :default-device="item.device" ></user-agent-parser>
          <div v-else>-</div>
        </template>
        <template #item.country="{ item }">
          <country-city :detail="item"></country-city>
        </template>
        <template #item.platform="{ item }">
          <v-icon
            :color="
              ['both', 'android'].includes(item.platform) ? 'primary' : 'grey'
            "
            v-text="'mdi-android'"
          ></v-icon>
          <v-icon
            :color="
              ['both', 'ios'].includes(item.platform) ? 'primary' : 'grey'
            "
            v-text="'mdi-apple'"
          ></v-icon>
        </template>
        <template #item.internal_notes="{ item }">
          <v-btn
            text
            icon
            class="text-transform-none mb-2 cursor-pointer"
            @click="updateNoteDrawer(item)"
          >
            <v-icon v-text="'edit'"></v-icon>
          </v-btn>
        </template>
        <template #item.status="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">
                <v-icon
                  :color="$options.STATUS[item.status].color"
                  v-text="$options.STATUS[item.status].icon"
                ></v-icon>
              </span>
            </template>
            <span>
              {{ $options.STATUS[item.status].title }}
            </span>
          </v-tooltip>
        </template>
        <template #item.created_at="{ item }">
          <vue-hoverable-date :date="item.created_at" />
        </template>
        <template #item.action="{ item }">
          <action-dropdown v-if="item.status !== 3">
            <template #list>
              <v-list-item
                v-if="[1, 2].includes(item.status)"
                @click="openFlagging(item.id, 3)"
              >
                <v-icon v-text="'mdi-flag-outline'" small></v-icon>
                Flag as inappropriate
              </v-list-item>
              <v-list-item
                v-if="[3].includes(item.status)"
                @click="updateStatus(item.id, 1)"
              >
                <v-icon v-text="'mdi-flag-outline'" small></v-icon>
                Undo flagging
              </v-list-item>
              <v-list-item
                v-if="[1].includes(item.status)"
                @click="updateStatus(item.id, 2)"
              >
                <v-icon small>mdi-check</v-icon>
                Mark as Resolved
              </v-list-item>
              <v-list-item
                v-if="[2].includes(item.status)"
                @click="updateStatus(item.id, 1)"
              >
                <v-icon small>mdi-check</v-icon>
                Mark as Not Resolved
              </v-list-item>
              <v-list-item @click="openDeleteDialog(item.id)">
                <v-icon small left>delete</v-icon>
                Delete
              </v-list-item>
            </template>
          </action-dropdown>
        </template>
        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <v-card tile flat>
              <v-card-text class="pb-4">
                <v-row>
                  <v-col v-if="!forInternalNoteEdit" cols="6">
                    <div class="mb-2">
                      <b>Message:</b>
                      <div>
                        <vue-readmore
                          :text="item.message || '-'"
                          :length="150"
                        ></vue-readmore>
                      </div>
                    </div>
                  </v-col>
                  <v-col v-if="forInternalNoteEdit" cols="12">
                    <v-row align="center">
                      <v-col cols="9" class="bold-text pb-0 text-left bodyFont">
                        Internal team notes
                      </v-col>
                      <v-col cols="3" class="text-right">
                        <v-btn class="text-right" @click="expendRow(item)" icon>
                          <v-icon v-text="'mdi-close'"></v-icon>
                        </v-btn>
                      </v-col>
                      <v-col class="py-0" cols="12">
                        <v-textarea
                          rows="4"
                          dense
                          filled
                          counter="600"
                          v-model="developer_note"
                        ></v-textarea>
                        <div class="error--text caption">
                          {{ updateNoteError }}
                        </div>
                      </v-col>
                      <v-col class="text-right" cols="12">
                        <v-btn
                          :loading="busy"
                          @click="updateNote(item)"
                          class="mr-3 text-transform-none"
                          color="primary"
                          depressed
                          small
                        >
                          Update
                        </v-btn>
                        <v-btn
                          text
                          :disabled="busy"
                          @click="expendRow(item)"
                          class="mr-3 text-transform-none"
                          color="primary"
                          small
                        >
                          Cancel
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </td>
        </template>
        <template slot="no-data">
          <no-data
            first-text="No subscribers for this app"
            second-text="Public users can subscribe from your app's public install page that can be generated from the Share page"
            permission-issues
          ></no-data>
        </template>
        <template #footer.page-text>
          <div class="center-el">
            <v-btn class="text-transform-none mx-2" @click="loadData()" text>
              <v-icon v-text="'mdi-reload'" color="grey" left> </v-icon>
              Reload
            </v-btn>
            <div>
              Total: <b>{{ getTotalSubscribersCount }}</b>
            </div>
          </div>
        </template>
      </v-data-table>
      <no-data
        v-else
        first-text="No app subscribers"
        second-text="You don't have the permission to view or manage the app subscribers"
        permission-issues
      ></no-data>
    </v-card-text>
    <Modal
      v-model="openSubscribersDeleteDialog"
      @close="openSubscribersDeleteDialog = false"
    >
      <template #message>
        <v-row>
          <v-col class="mt-4 font-size-h3 bold-text" cols="12">
            Delete subscriber
          </v-col>
          <v-col class="bodyFont" cols="12">
            Are you sure you want to delete this subscriber?
          </v-col>
          <v-col class="bodyFont" cols="12">
            <p>There's no undo for this action.</p>
          </v-col>
          <v-col class="text-right" cols="12">
            <v-btn
              text
              class="text-transform-none"
              color="primary"
              :disabled="busy"
              @click="openSubscribersDeleteDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              depressed
              class="text-transform-none"
              color="primary"
              :loading="busy"
              @click="deleteFeedback"
            >
              Yes
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal
      v-model="openFlaggingConfirmModal"
      @close="openFlaggingConfirmModal = false"
    >
      <template #message>
        <v-row>
          <v-col class="mt-4 font-size-h3 bold-text" cols="12">
            Confirm Flagging
          </v-col>
          <v-col class="bodyFont" cols="12">
            Are you sure you want to flag this feedback?
          </v-col>
          <v-col class="text-right" cols="12">
            <v-btn
              text
              class="text-transform-none"
              color="primary"
              :disabled="busy"
              @click="openFlaggingConfirmModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              depressed
              class="text-transform-none"
              color="primary"
              :loading="busy"
              @click="updateStatus()"
            >
              Yes
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
  </v-card>
</template>

<script>
import ProfileImage from "@/view/components/User/Image.vue";
import { isEmpty, get } from "@/core/services/helper.service";
import { mapGetters, mapMutations } from "vuex";
import VueReadmore from "@/view/components/Common/VueReadmore";
import {
  DELETE_SUBSCRIBERS,
  GET_SUBSCRIBERS_LIST,
  UPDATE_SUBSCRIBERS_NOTE,
  UPDATE_SUBSCRIBERS_STATUS,
} from "@/store/apps/subscribers.module";
import UserAgentParser from "@/view/components/Common/UserAgentParser";
import CountryCity from "@/view/components/Common/CountryCity";
export default {
  components: { CountryCity, UserAgentParser, VueReadmore },
  props: {
    components: { ProfileImage },
    fromApp: {
      type: Boolean,
      default: false,
    },
    fromShare: {
      type: Boolean,
      default: false,
    },
    shareId: {
      type: String,
      default: "",
    },
    release: {
      type: [Object, Boolean],
      default() {
        return false;
      },
    },
  },
  STATUS: {
    1: {
      title: "Not Resolved",
      color: "orange",
      icon: "mdi-clock-outline",
    },
    3: {
      title: "Flagged for review ",
      color: "red",
      icon: "mdi-flag-outline",
    },
    2: {
      title: "Resolved",
      color: "green",
      icon: "mdi-check-circle-outline",
    },
  },
  data() {
    return {
      isEmpty,
      get,
      page: 1,
      developer_note: "",
      menu: false,
      expanded: [],
      openNoteModal: false,
      headers: [
        {
          text: "Name",
          align: "start",
          width: "15%",
          sortable: false,
          value: "name",
        },
        {
          text: "Device",
          align: "start",
          sortable: false,
          width: "15%",
          value: "device",
        },
        {
          text: "Location",
          align: "start",
          sortable: false,
          width: "10%",
          value: "country",
        },
        {
          text: "Platform",
          align: "end",
          sortable: false,
          width: "5%",
          value: "platform",
        },
        {
          text: "Notes",
          align: "end",
          sortable: false,
          width: "5%",
          value: "internal_notes",
        },
        {
          text: "Subscribed",
          align: "start",
          sortable: false,
          width: "10%",
          value: "created_at",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          width: "5%",
          value: "status",
        },
        {
          text: "Action",
          align: "start",
          sortable: false,
          width: "5%",
          value: "action",
        },
      ],
      busy: false,
      selectedId: "",
      selectedStatus: "",
      forInternalNoteEdit: false,
      updateNoteError: null,
      openFlaggingConfirmModal: false,
      openSubscribersDeleteDialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      getSubscribersList: "getSubscribersList",
      getTotalSubscribersCount: "getTotalSubscribersCount",
      getTotalSubscribersList: "getTotalSubscribersList",
    }),
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalSubscribersList.length > oldVal * 10) {
          this.setPreviousSubscriber(
            this.getTotalSubscribersList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId =
            this.getSubscribersList[this.getSubscribersList.length - 1].id;
          this.loadData(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousSubscriber(
          this.getTotalSubscribersList.slice(offset, limit)
        );
      }
    },
  },
  created() {
    if (this.canManageApp) {
      this.loadData();
    }
  },
  methods: {
    ...mapMutations({
      setPreviousSubscriber: "setPreviousSubscriber",
      updateSubscribersNote: "updateSubscribersNote",
      updateSubscribersStatus: "updateSubscribersStatus",
      removeDeletedSubscription: "removeDeletedSubscription",
    }),
    openUpdateMenu(item) {
      this.menu = true;
      this.selectedId = item.id;
      this.developer_note = item.note;
    },
    closeMenu() {
      this.menu = false;
      this.selectedId = "";
      this.developer_note = "";
    },
    loadData(lastID) {
      if (!lastID) {
        this.page = 1;
      }
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_SUBSCRIBERS_LIST, {
          app_id: this.$route.params.app_id,
          release_id: this.release.id || "",
          shareId: this.shareId || "",
          lastId: lastID || "",
        })
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openFlagging(id, status) {
      this.selectedId = id;
      this.selectedStatus = status;
      this.openFlaggingConfirmModal = true;
    },
    updateNoteDrawer(details) {
      this.forInternalNoteEdit = true;
      this.developer_note = details.note;
      this.selectedId = details.id;
      this.expendRow(details);
    },
    updateNote(item) {
      this.updateNoteError = null;
      if (this.busy) return;
      this.busy = true;
      let payload = {
        app_id: this.$route.params.app_id,
        subscribe_id: this.selectedId,
        note: this.developer_note,
      };
      this.$store
        .dispatch(UPDATE_SUBSCRIBERS_NOTE, payload)
        .then((response) => {
          this.updateSubscribersNote({
            id: this.selectedId,
            note: this.developer_note,
          });
          this.openFlaggingConfirmModal = false;
          this.selectedId = "";
          this.selectedStatus = "";
          this.busy = false;
          this.expendRow(item);
          this.developer_note = "";
          this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.updateNoteError = err.message;
          this.busy = false;
        });
    },
    updateStatus(feedbackId, status) {
      if (this.busy) return;
      this.busy = true;
      let payload = {
        app_id: this.$route.params.app_id,
        subscribe_id: feedbackId || this.selectedId,
        status: status || this.selectedStatus,
      };
      this.$store
        .dispatch(UPDATE_SUBSCRIBERS_STATUS, payload)
        .then(() => {
          this.busy = false;
          this.openFlaggingConfirmModal = false;
          this.updateSubscribersStatus({
            id: feedbackId || this.selectedId,
            status: status || this.selectedStatus,
          });
          // this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openDeleteDialog(subscribeId) {
      this.selectedId = subscribeId;
      this.openSubscribersDeleteDialog = true;
    },
    deleteFeedback() {
      if (this.busy) return;
      this.busy = true;
      let payload = {
        app_id: this.$route.params.app_id,
        subscribe_id: this.selectedId,
      };
      this.$store
        .dispatch(DELETE_SUBSCRIBERS, payload)
        .then((response) => {
          this.busy = false;
          this.removeDeletedSubscription(payload.subscribe_id);
          this.openSubscribersDeleteDialog = false;
          this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    expendRow(item) {
      if (!item.value) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      }
    },
  },
};
</script>

<style scoped></style>
