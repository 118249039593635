<template>
  <!--
    ****  Install Modal
    *** expressAndBoth => Express and both platform
    ** i == 1 means android, and i == 2 means ios
    ** remember we have loop in Express (split modal)
    ** we're passing the i var in all computed, appName(i), version(i), appPlatform(i)...
    *** i is the index so we need to check it as well
    **** i == 1 means android, and i == 2 means ios
  -->
  <div class="py-4">
    <v-row no-gutters>
      <!-- loop for split in Express -->
      <v-col
        cols="12"
        :class="{
          'divider-first':
            i == 1 && (expressAndBoth || $vuetify.breakpoint.mdOnly),
        }"
        :md="expressAndBoth ? 6 : 12"
        v-for="i in expressAndBoth ? 2 : 1"
        :key="i"
      >
        <!-- Title -->
        <h2
          class="display-1 mb-4 mt-2 text-center bold-text text-capitalize mx-4"
        >
          Install
          <span v-if="appName(i)" :class="{ 'd-block': expressAndBoth }">
            “{{ appName(i) }}”
          </span>
        </h2>

        <!-- Version and Verion code -->
        <div
          class="mx-4 font-size-h3 text-center darkGrey--text"
          v-if="expressAndBoth || !both"
        >
          <template v-if="expressAndBoth">
            {{
              i == 1
                ? `v${appInfo.apk.info.version} (${appInfo.apk.info.version_code})`
                : `v${appInfo.ipa.info.version} (${appInfo.ipa.info.version_code})`
            }}
          </template>
          <template v-else>
            {{ `v${appInfo.version} (${appInfo.version_code})` }}
          </template>
        </div>

        <!--  -->
        <!-- Platform -->
        <!--  -->
        <div class="pt-2 mx-4 font-size-h3 text-center darkGrey--text">
          <span v-if="expressAndBoth">
            {{ i == 1 ? "For android" : "For ios" }}
          </span>
          <span v-else> {{ platform ? `For ${platform}` : "" }} </span>
        </div>

        <!--  -->
        <!-- Install -->
        <!--  -->
        <div v-if="getMobileOperatingSystem !== 'unknown'">
          <install-button
            v-if="!['android', 'ios'].includes(platform)"
            :release-id="appInfo.releaseId || null"
            :is-public="appInfo.public"
            :release-url="appInfo.url"
            :app-id="appInfo.id"
          ></install-button>
          <install-button
            v-if="
              (platform === 'android' &&
                getMobileOperatingSystem === 'android') ||
              (expressAndBoth && i == 1)
            "
            :is-public="appInfo.public"
            platform="android"
            :release-url="appInfo.url"
            :release-id="appInfo.releaseId || null"
            :app-id="appInfo.id"
          ></install-button>
          <!-- android -->

          <!-- ios -->
          <install-button
            v-if="
              (platform === 'ios' && getMobileOperatingSystem === 'ios') ||
              (expressAndBoth && i == 2)
            "
            platform="ios"
            :is-public="appInfo.public"
            :release-url="appInfo.url"
            :release-id="appInfo.releaseId || null"
            :app-id="appInfo.id"
          ></install-button>
        </div>

        <div>
          <!--  -->
          <!-- Qr code -->
          <!--  -->
          <div class="d-flex justify-center align-center">
            <qrcode :value="link(i)" :options="{ width: 200 }" />
          </div>
          <div
            class="font-size-h4 text-center mx-7 mb-2 mt-2"
            v-if="both || !platform"
          >
            Scan this QR code from your device
          </div>
          <div
            v-else-if="platform"
            class="text-center mb-6 font-size-h4 darkGrey--text mx-auto"
            style="max-width: 360px"
          >
            {{
              `Scan this QR code from your ${platform} device or open our app to install it`
            }}
          </div>

          <!--  -->
          <!-- invite members -->
          <!--  -->
          <div
            style="max-width: 400px"
            class="mx-auto bodyFont mt-4 mb-5"
            v-if="both && !isExpress"
          >
            Note: You can invite
            <InviteMembersPanel
              hide-overlay
              display-text="new team members"
              class="d-inline primary--text"
            />
            at anytime from Team page to access this app and others.
          </div>

          <!--  -->
          <!-- URL -->
          <!--  -->
          <div class="mt-4 text-center">
            <v-chip class="radius-10 darkGrey--text">
              <MaxText :text="link(i)" :max="34" />
            </v-chip>
            <v-icon
              class="ml-2"
              @click="copy(link(i))"
              v-text="'mdi-content-copy'"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InviteMembersPanel from "@/view/components/Invites/InviteMembersPanel.vue";
import { mapGetters, mapMutations } from "vuex";
import InstallButton from "@/view/components/Common/InstallButton";
export default {
  components: {
    InstallButton,
    InviteMembersPanel,
  },
  props: {
    fromAppInfo: Boolean,
    isExpress: Boolean,
    loading: Boolean,
    platform: String,
    appInfo: Object,
    both: Boolean,
    expressAndBoth: [Object, Array],
  },
  data: () => ({
    dialog: null,
    copyValue: "",
  }),
  created() {
    window.analytics.track("Opened install release popup", {
      platform: this.platform,
      app_id: this.appInfo.id,
      release_id: this.appInfo.releaseId,
      from_app_info: this.fromAppInfo,
      is_express: this.isExpress,
    });
  },
  computed: {
    ...mapGetters({
      getTeamId: "getTeamId",
    }),
    appName() {
      return (i) => {
        if (this.expressAndBoth) {
          // expressAndBoth => Express and both platform
          // i == 1 means android, and i == 2 means ios
          return i == 1
            ? this.appInfo.apk.info.name
            : this.appInfo.ipa.info.name;
        } else {
          // return general name
          return this.appInfo.name;
        }
      };
    },
    link() {
      return (i) => {
        if (this.isExpress) {
          return this.expressAndBoth // expressAndBoth => Express and both platform
            ? `${location.origin}/e/${
                i == 1 ? this.appInfo.androidId : this.appInfo.iosId
              }`
            : `${location.origin}/e/${this.appInfo.releaseId}`;
        } else if (this.both) {
          return `${location.origin}/install/${this.appInfo.id}${this.appInfo.public ? '' : '/?team_id=' + this.getTeamId}`  ;
        } else {
          // default link
          return `${location.origin}/install/${this.appInfo.id}/${this.appInfo.releaseId}${this.appInfo.public ? '' : '/?team_id=' + this.getTeamId}`;
        }
      };
    },
  },
  methods: {
    ...mapMutations({
      setDefaultApp: "setDefaultApp",
    }),
    closeInstall() {
      this.$emit("close");
      this.$route.query.install ? this.$router.replace(this.$route.path) : null;
    },
    setDefaultValue() {
      this.setDefaultApp({
        id: this.appInfo.id,
        name: this.appInfo.name,
        image: this.appInfo.image,
        platforms: this.appInfo.platforms,
      });
    },
  },
  async mounted() {},
};
</script>
<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";
.divider-first {
  border-right: 1px solid #ddd;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    border-left: none;
    border-bottom: 1px solid #ddd;
    padding-bottom: 34px !important;
    margin-bottom: 15px;
  }
}
</style>
