<template>
  <div>
    <v-card outlined class="">
      <v-card-title v-if="!fromLatest">
        <div>
          <div v-if="!fromShare" class="font-size-h3 dark-grey-text bold-text">
            {{ fromApp ? "App" : "Release" }} feedback
            <v-chip x-small>BETA</v-chip>
          </div>
          <div
            v-if="fromApp || fromShare"
            class="text-muted bold-text text-body-1"
          >
            <!-- List of feedback provided from both our app (internal testers) and
            the public install page (external testers) -->

            {{ getFeedbackPageMessage }}

            <div class="mt-2" v-if="canManageApp">
              Connect your
              <a @click="$router.push({ name: 'integrations' })">Slack</a> to
              get notified about new feedback and other important events.
            </div>
          </div>
          <div v-if="fromLatest" class="text-muted bold-text text-body-1">
            Feedback provided for this release
          </div>
        </div>
      </v-card-title>
      <v-divider v-if="!fromLatest"></v-divider>
      <v-card-text class="pa-0">
        <v-skeleton-loader
          class="transparent"
          type="table-thead, table-tbody"
          v-if="loading"
        />
        <v-data-table
          v-else
          :headers="headers"
          :items="getReleaseInstallFeedback"
          :page.sync="page"
          :expanded="expanded"
          class="hide-v-data-footer__select release-table"
          :items-per-page="10"
          :server-items-length="getTotalReleaseInstallFeedbackCount"
        >
          <template #item.name="{ item }">
            <div class="ml-4">
              <MaxText :text="get(item, 'user.name', '')" max="20" />
              <div class="text-muted">
                <MaxText :text="get(item, 'user.email', '')" max="20" />
              </div>
            </div>
          </template>
          <template #item.message="{ item }">
            <div class="pt-2">
              <vue-readmore
                :text="item.message || '-'"
                :length="30"
                @readMore="expendRow(item, 'message')"
                small
              ></vue-readmore>
            </div>
          </template>
          <template #item.device="{ item }">
            <user-agent-parser
              :default-device="item.device"
            ></user-agent-parser>
          </template>
          <template #item.country="{ item }">
            <country-city :detail="item"></country-city>
          </template>
          <template #item.rating="{ item }">
            <div v-if="item.rating">
              <v-icon small color="black" v-text="'mdi-star-outline'"></v-icon>
              {{ item.rating }}
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on"> - </span>
                </template>
                <span>No rating</span>
              </v-tooltip>
            </div>
          </template>
          <template #item.release="{ item }">
            <div v-if="item.release && item.release.status">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-icon
                      small
                      left
                      :color="
                        $options.RELEASE_STATUS[item.release.status].color
                      "
                      v-text="
                        item.release.platform === 'android'
                          ? 'mdi-android'
                          : 'mdi-apple'
                      "
                    ></v-icon>
                  </span>
                </template>
                <span>
                  <ul>
                    <li>
                      Status:
                      {{ $options.RELEASE_STATUS[item.release.status].title }}
                    </li>
                    <li>
                      Version:
                      {{ item.release.version }}
                    </li>
                    <li>
                      Version Code:
                      {{ item.release.version_code }}
                    </li>
                    <li>
                      Size:
                      {{ formatBytes(item.release.size) }}
                    </li>
                  </ul>
                </span>
              </v-tooltip>
            </div>
            <!-- No release selected -->
            <div v-else>-</div>
          </template>
          <template #item.share_link="{ item }">
            <div class="cursor-pointer primary--text">View</div>
          </template>
          <template #item.notes="{ item }">
            <v-btn
              v-if="canManageApp"
              text
              icon
              class="text-transform-none mb-2 cursor-pointer"
              @click="updateNoteDrawer(item)"
            >
              <v-icon v-text="'edit'"></v-icon>
            </v-btn>
            <div v-else>-</div>
          </template>
          <template #item.status="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on">
                  <v-icon
                    :color="$options.STATUS[item.status].color"
                    v-text="$options.STATUS[item.status].icon"
                  ></v-icon>
                </span>
              </template>
              <span>
                {{ $options.STATUS[item.status].title }}
              </span>
            </v-tooltip>
          </template>
          <template #item.created_at="{ item }">
            <vue-hoverable-date :date="item.created_at" />
          </template>
          <template #item.action="{ item }">
            <action-dropdown v-if="item.status !== 3">
              <template #list>
                <v-list-item
                  v-if="[1, 2].includes(item.status) && canManageApp"
                  @click="openFlagging(item.id, 3)"
                >
                  <v-icon v-text="'mdi-flag-outline'" small></v-icon>
                  Flag as Inappropriate
                </v-list-item>
                <v-list-item
                  v-if="[1].includes(item.status) && canManageApp"
                  @click="updateStatus(item.id, 2)"
                >
                  <v-icon small>mdi-check</v-icon>
                  Mark as Resolved
                </v-list-item>
                <v-list-item
                  v-if="[2].includes(item.status) && canManageApp"
                  @click="updateStatus(item.id, 1)"
                >
                  <v-icon small>mdi-check</v-icon>
                  Mark as Not Resolved
                </v-list-item>
                <v-list-item
                  v-if="item.user.id === currentUser.id"
                  @click="openUpdateFeedback(item)"
                >
                  <v-icon small>edit</v-icon>
                  update
                </v-list-item>
                <v-list-item @click="openDeleteDialog(item.id)">
                  <v-icon small left>delete</v-icon>
                  Delete
                </v-list-item>
              </template>
            </action-dropdown>
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
              <v-card tile flat>
                <v-card-text class="pb-4">
                  <v-row>
                    <v-col v-if="!fromUpdateNote" cols="12">
                      <div class="mb-2">
                        <b>Message:</b>
                        <div style="white-space: pre-line">
                          {{ item.message || "-" }}
                        </div>
                      </div>
                    </v-col>
                    <v-col v-if="fromUpdateNote" cols="12">
                      <v-row align="center">
                        <v-col cols="9" class="bold-text text-left bodyFont">
                          Internal team notes
                        </v-col>
                        <v-col class="py-0" cols="12">
                          <v-textarea
                            rows="4"
                            filled
                            dense
                            counter="600"
                            v-model="developer_note"
                          ></v-textarea>
                          <div class="error--text caption">
                            {{ updateNoteError }}
                          </div>
                        </v-col>
                        <v-col class="text-right" cols="12">
                          <v-btn
                            :loading="busy"
                            @click="updateNote(item)"
                            class="mr-3 text-transform-none"
                            color="primary"
                            depressed
                            small
                          >
                            Update
                          </v-btn>
                          <v-btn
                            text
                            :disabled="busy"
                            @click="expendRow(item)"
                            class="mr-3 text-transform-none"
                            color="primary"
                            small
                          >
                            Cancel
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </td>
          </template>
          <template #no-data>
            <v-row>
              <v-col cols="12">
                <no-data
                  v-if="!canManageApp"
                  first-text="You didn't provide any feedback for this release"
                  second-text="From our app in the app's releases tab, you can provide your feedback for the developers and app managers"
                  permission-issues
                ></no-data>
                <no-data
                  v-else
                  first-text="No feedback has been given to this release, yet!"
                  second-text="Feedback can be collected from the public install page (generated from Share page) or from our App in app releases page"
                ></no-data>
              </v-col>
            </v-row>
          </template>
          <template #footer.page-text>
            <div v-if="canManageApp">
              <v-btn class="text-transform-none" @click="loadData()" text>
                <v-icon v-text="'mdi-reload'" color="grey" left> </v-icon>
                Reload
              </v-btn>
              Total: <b>{{ getTotalReleaseInstallFeedbackCount }}</b>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <Modal
        v-model="openFeedbackDeleteDialog"
        @close="openFeedbackDeleteDialog = false"
      >
        <template #message>
          <v-row>
            <v-col class="mt-4 font-size-h3 bold-text" cols="12">
              Delete feedback
            </v-col>
            <v-col class="bodyFont" cols="12">
              Are you sure you want to delete this feedback?
            </v-col>
            <v-col class="bodyFont" cols="12">
              <p>There's no undo for this action.</p>
            </v-col>
            <v-col class="text-right" cols="12">
              <v-btn
                text
                class="text-transform-none"
                color="primary"
                :disabled="busy"
                @click="openFeedbackDeleteDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                depressed
                class="text-transform-none"
                color="primary"
                :loading="busy"
                @click="deleteFeedback"
              >
                Yes
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </Modal>
      <Modal
        v-model="openFlaggingConfirmModal"
        @close="openFlaggingConfirmModal = false"
      >
        <template #message>
          <v-row>
            <v-col class="mt-4 font-size-h3 bold-text" cols="12">
              Confirm Flagging
            </v-col>
            <v-col class="bodyFont" cols="12">
              Are you sure you want to flag this feedback?
            </v-col>
            <v-col class="text-right" cols="12">
              <v-btn
                text
                class="text-transform-none"
                color="primary"
                :disabled="busy"
                @click="openFlaggingConfirmModal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                depressed
                class="text-transform-none"
                color="primary"
                :loading="busy"
                @click="updateStatus()"
              >
                Yes
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </Modal>
      <Modal
        v-model="openUpdateFeedbackForm"
        @close="openUpdateFeedbackForm = false"
      >
        <template #message>
          <v-row>
            <v-col class="mt-4 font-size-h3 bold-text" cols="12">
              Update feedback
            </v-col>
            <v-col cols="12">
              <v-textarea
                filled
                v-model="formValues.message"
                v-validate="'max:600'"
                v-bind="veeValidate('Message', '')"
                rows="4"
                counter="600"
                label="Message"
                hide-details="auto"
                auto-grow
                placeholder="Do you have any feedback for us?"
                persistent-hint
              >
              </v-textarea>
            </v-col>
            <v-col cols="6" md="6">
              <div class="bodyFont mb-1">Rating</div>
              <v-rating
                dense
                hover
                label="Rate"
                v-model="formValues.rating"
                length="5"
                size="23"
                value="5"
              ></v-rating>
            </v-col>
            <v-col class="text-right" cols="12">
              <v-btn
                :disabled="busy"
                @click="openUpdateFeedbackForm = false"
                class="mr-2 text-transform-none"
                text
                color="primary"
              >
                Cancel
              </v-btn>
              <v-btn
                @click="updateFeedback"
                :loading="busy"
                class="text-transform-none"
                depressed
                color="primary"
              >
                Update
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </Modal>
    </v-card>
  </div>
</template>

<script>
import {
  DELETE_INSTALL_FEEDBACK,
  GET_RELEASE_INSTALL_FEEDBACK,
  UPDATE_FEEDBACK_DETAIL,
  UPDATE_FEEDBACK_INTERNAL_NOTES,
  UPDATE_FEEDBACK_STATUS,
} from "@/store/releases/releases.module";
import ProfileImage from "@/view/components/User/Image.vue";
import { isEmpty, get, formatBytes } from "@/core/services/helper.service";
import { mapGetters, mapMutations } from "vuex";
import VueReadmore from "@/view/components/Common/VueReadmore";
import UserAgentParser from "@/view/components/Common/UserAgentParser";
import CountryCity from "@/view/components/Common/CountryCity";
import veeValidate from "@/mixins/veeValidate";
export default {
  components: { CountryCity, UserAgentParser, VueReadmore },
  mixins: [veeValidate],
  props: {
    components: { ProfileImage },
    fromApp: {
      type: Boolean,
      default: false,
    },
    fromShare: {
      type: Boolean,
      default: false,
    },
    fromLatest: {
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      default: "",
    },
    shareId: {
      type: String,
      default: "",
    },
    release: {
      type: [Object, Boolean],
      default() {
        return false;
      },
    },
  },
  STATUS: {
    1: {
      title: "Not Resolved",
      color: "orange",
      icon: "mdi-clock-outline",
    },
    3: {
      title: "Flagged for review ",
      color: "red",
      icon: "mdi-flag-outline",
    },
    2: {
      title: "Resolved",
      color: "green",
      icon: "mdi-check-circle-outline",
    },
  },
  RELEASE_STATUS: {
    1: { title: "Active", color: "green" },
    2: { title: "Failed", color: "red" },
    3: { title: "Archived", color: "grey" },
    4: { title: "Uploading", color: "orange" },
    5: { title: "Processing", color: "primary" },
  },
  data() {
    return {
      isEmpty,
      get,
      formatBytes,
      feedbackList: [],
      openUpdateFeedbackForm: false,
      page: 1,
      developer_note: "",
      menu: false,
      expanded: [],
      selectedId: "",
      selectedStatus: "",
      openNoteModal: false,
      formValues: {
        message: "",
        rating: "",
      },
      headers: [
        {
          text: "Name",
          align: "start",
          width: "10%",
          sortable: false,
          value: "name",
        },
        {
          text: "Message",
          align: "start",
          sortable: false,
          width: "20%",
          value: "message",
        },
        {
          text: "Device",
          align: "start",
          sortable: false,
          width: "3%",
          value: "device",
        },
        {
          text: "Location",
          align: "start",
          sortable: false,
          width: "10%",
          value: "country",
        },
        {
          text: "Rating",
          align: "end",
          sortable: false,
          width: "3%",
          value: "rating",
        },
        {
          text: "Release",
          align: "center",
          sortable: false,
          width: "3%",
          value: "release",
        },
        // {
        //   text: "Share link",
        //   align: "start",
        //   sortable: false,
        //   width: "9%",
        //   value: "share_link",
        // },
        {
          text: "Notes",
          align: "start",
          sortable: false,
          width: "4%",
          value: "notes",
        },
        {
          text: "Submitted",
          align: "start",
          sortable: false,
          width: "10%",
          value: "created_at",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          width: "2%",
          value: "status",
        },
        {
          text: "Action",
          align: "end",
          sortable: false,
          width: "2%",
          value: "action",
        },
      ],
      busy: false,
      updateNoteError: "",
      selectedData: "",
      fromUpdateNote: false,
      openFlaggingConfirmModal: false,
      openFeedbackDeleteDialog: false,
      loading: false,
    };
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalReleaseInstallFeedback.length > oldVal * 10) {
          this.setPreviousReleaseFeedback(
            this.getTotalReleaseInstallFeedback.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId =
            this.getReleaseInstallFeedback[
              this.getReleaseInstallFeedback.length - 1
            ].id;
          this.loadData(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousReleaseFeedback(
          this.getTotalReleaseInstallFeedback.slice(offset, limit)
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      getReleaseInstallFeedback: "getReleaseInstallFeedback",
      getLatestAndroidFeedback: "getLatestAndroidFeedback",
      getLatestIosFeedback: "getLatestIosFeedback",
      getLatestAndroidFeedbackCount: "getLatestAndroidFeedbackCount",
      getLatestIosFeedbackCount: "getLatestIosFeedbackCount",
      getTotalReleaseInstallFeedbackCount:
        "getTotalReleaseInstallFeedbackCount",
      getFeedbackPageMessage: "getFeedbackPageMessage",
      getTotalReleaseInstallFeedback: "getTotalReleaseInstallFeedback",
    }),
  },
  created() {
    if (!this.canManageApp) {
      this.headers = this.headers.filter(
        (item) => !["notes", "status"].includes(item.value)
      );
    }

    if (this.fromLatest && this.platform === "android") {
      if (this.getLatestAndroidFeedback) {
        this.setInstallReleaseFeedback(this.getLatestAndroidFeedback);
        this.setTotalInstallReleaseCount(this.getLatestAndroidFeedbackCount);
      }
    }
    if (this.fromLatest && this.platform === "ios") {
      if (this.getLatestIosFeedback) {
        this.setInstallReleaseFeedback(this.getLatestIosFeedback);
        this.setTotalInstallReleaseCount(this.getLatestIosFeedbackCount);
      }
    }
    if (
      (this.fromLatest &&
        this.platform === "ios" &&
        this.getLatestIosFeedback) ||
      (this.platform === "android" && this.getLatestAndroidFeedback)
    ) {
      return;
    } else {
      this.loadData();
    }
  },
  methods: {
    ...mapMutations({
      setPreviousReleaseFeedback: "setPreviousReleaseFeedback",
      updateFeedbackNote: "updateFeedbackNote",
      updateInstallFeedbackStatus: "updateInstallFeedbackStatus",
      setLatestAndroidFeedback: "setLatestAndroidFeedback",
      setLatestIosFeedback: "setLatestIosFeedback",
      setInstallReleaseFeedback: "setInstallReleaseFeedback",
      setTotalInstallReleaseCount: "setTotalInstallReleaseCount",
      setLatestAndroidFeedbackCount: "setLatestAndroidFeedbackCount",
      setLatestIosFeedbackCount: "setLatestIosFeedbackCount",
      removeFeedbackDeleteData: "removeFeedbackDeleteData",
      updateLatestReleaseFeedbackCount: "updateLatestReleaseFeedbackCount",
      updateFeedbackMessageAndRating: "updateFeedbackMessageAndRating",
    }),
    openUpdateMenu(item) {
      this.menu = true;
      this.selectedId = item.id;
      this.developer_note = item.note;
    },
    closeMenu() {
      this.menu = false;
      this.selectedId = "";
      this.developer_note = "";
    },
    loadData(lastID) {
      if (!lastID) {
        this.page = 1;
      }
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_RELEASE_INSTALL_FEEDBACK, {
          app_id: this.$route.params.app_id,
          release_id: this.release.id || "",
          lastId: lastID || "",
          shareId: this.shareId,
        })
        .then((data) => {
          if (
            this.fromLatest &&
            this.platform === "ios" &&
            !this.getLatestIosFeedback
          ) {
            this.setLatestIosFeedback(data.data);
            this.setLatestIosFeedbackCount(data.meta.count);
          }
          if (
            this.fromLatest &&
            this.platform === "android" &&
            !this.getLatestAndroidFeedback
          ) {
            this.setLatestAndroidFeedback(data.data);
            this.setLatestAndroidFeedbackCount(data.meta.count);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    updateNoteDrawer(detail) {
      this.developer_note = detail.note;
      this.fromUpdateNote = true;
      this.expendRow(detail);
    },
    openUpdateFeedback(detail) {
      this.selectedId = detail.id;
      this.formValues.message = detail.message;
      this.formValues.rating = detail.rating;
      this.openUpdateFeedbackForm = true;
    },
    async updateFeedback() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        const dataToPost = {
          app_id: this.$route.params.app_id,
          message: this.formValues.message,
          rating: this.formValues.rating,
          feedback_id: this.selectedId,
        };
        this.$store
          .dispatch(UPDATE_FEEDBACK_DETAIL, dataToPost)
          .then((response) => {
            this.busy = false;
            this.openUpdateFeedbackForm = false;
            this.updateFeedbackMessageAndRating(dataToPost);
            this.notifyUserMessage(response.message);
          })
          .catch((err) => {
            this.busy = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
    updateNote(detail) {
      this.updateNoteError = "";
      if (this.busy) return;
      this.busy = true;
      let payload = {
        app_id: this.$route.params.app_id,
        feedback_id: detail.id,
        note: this.developer_note,
      };
      this.$store
        .dispatch(UPDATE_FEEDBACK_INTERNAL_NOTES, payload)
        .then((response) => {
          this.updateFeedbackNote({ id: detail.id, note: this.developer_note });
          this.busy = false;
          this.expendRow(detail);
          this.developer_note = "";
          this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.busy = false;
          this.updateNoteError = err.message;
        });
    },
    openFlagging(id, status) {
      this.selectedId = id;
      this.selectedStatus = status;
      this.openFlaggingConfirmModal = true;
    },
    updateStatus(feedbackId, status) {
      if (this.busy) return;
      this.busy = true;
      let payload = {
        app_id: this.$route.params.app_id,
        feedback_id: feedbackId || this.selectedId,
        status: status || this.selectedStatus,
      };
      this.$store
        .dispatch(UPDATE_FEEDBACK_STATUS, payload)
        .then(() => {
          this.busy = false;
          if (feedbackId || (this.selectedStatus == 3 && this.release.id)) {
            this.updateLatestReleaseFeedbackCount({ id: this.release.id });
          }

          this.updateInstallFeedbackStatus({
            id: feedbackId || this.selectedId,
            status: status || this.selectedStatus,
          });

          this.openFlaggingConfirmModal = false;
          this.selectedId = "";
          this.selectedStatus = "";
          // this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openDeleteDialog(feedbackId) {
      this.selectedId = feedbackId;
      this.openFeedbackDeleteDialog = true;
    },
    deleteFeedback() {
      if (this.busy) return;
      this.busy = true;
      let payload = {
        app_id: this.$route.params.app_id,
        feedback_id: this.selectedId,
      };
      this.$store
        .dispatch(DELETE_INSTALL_FEEDBACK, payload)
        .then((response) => {
          this.removeFeedbackDeleteData({ id: this.selectedId });
          this.busy = false;
          this.openFeedbackDeleteDialog = false;
          this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    expendRow(item, message) {
      this.updateNoteError = "";
      if (message) {
        this.fromUpdateNote = false;
      }
      if (!item.value) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      }
    },
  },
};
</script>

<style scoped></style>
