var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.full)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.defaultDevice && _vm.isEmpty(_vm.defaultDevice))?_c('v-icon',_vm._g({domProps:{"textContent":_vm._s(
          ['Android', 'iOS'].includes(_vm.os && _vm.os.name)
            ? 'mdi-cellphone'
            : 'mdi-monitor'
        )}},on)):_c('v-icon',_vm._g({domProps:{"textContent":_vm._s(
          ['Android', 'iOS'].includes(_vm.defaultDevice.os)
            ? 'mdi-cellphone'
            : 'mdi-monitor'
        )}},on))]}}],null,false,1815338620)},[_c('span',[(_vm.defaultDevice && !_vm.isEmpty(_vm.defaultDevice))?_c('div',[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.defaultDevice.brand)+" ")]),_c('span',[_vm._v(_vm._s(_vm.get(_vm.defaultDevice, "device", "-")))]),_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.defaultDevice.os + " " + _vm.get(_vm.defaultDevice, "version", ""))+" ")])]):_vm._e()])])],1):_c('div',{staticClass:"py-3 pl-2"},[(_vm.defaultDevice && !_vm.isEmpty(_vm.defaultDevice))?_c('div',[_c('v-row',{staticClass:"text-left"},[_c('v-col',{staticClass:"pt-0 pb-0 pl-0 pr-2 my-auto",attrs:{"cols":"auto"}},[_c('v-icon',{domProps:{"textContent":_vm._s(
            ['Android', 'iOS'].includes(_vm.defaultDevice.os)
              ? 'mdi-cellphone'
              : 'mdi-monitor'
          )}})],1),_c('v-col',{staticClass:"pt-0 pb-0 pl-0 pr-0",attrs:{"cols":"auto"}},[(_vm.defaultDevice && !_vm.isEmpty(_vm.defaultDevice))?_c('div',[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.defaultDevice.brand)+" ")]),_c('span',[_vm._v(_vm._s(_vm.get(_vm.defaultDevice, "device", "-")))]),_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.defaultDevice.os + " " + _vm.get(_vm.defaultDevice, "version", ""))+" ")])]):_vm._e()])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }